import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { ApiClient } from './api-client';
import { Storage } from '@ionic/storage';
import { Events, NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  userApiUrl = '/user';
  authApiUrl = '/auth';
  public user: User;
  public userPermitViewInvoice: Subject<boolean> = new BehaviorSubject<boolean>(false);
  isAuthenticated = false;
  isMenuDisabled = true;

  constructor(
    public apiClient: ApiClient,
    private storage: Storage,
    private navCtrl: NavController,
    private events: Events
  ) {}

  getUserInfo(): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      if (this.user) {
        resolve(this.user);
      } else {
        this.getUser().subscribe(
          (user) => resolve(user),
          (error) => reject(error)
        );
      }
    });
  }

  getUser(): Observable<User> {
    return this.apiClient.get(this.userApiUrl)
        .pipe(map(user => {
          this.setUser(user);
          // the correct check should be for the ID 1; it got changed to block the view for now
          this.userPermitViewInvoice.next(user.permissions.filter(p => p.id === 9999).length > 0);
          return user;
        }));
  }

  setUser(userData: any): void {
      this.user = {
        ...userData
      };

      this.storage.set('localUser', this.user);
  }

  registerUser(user: User): Observable<any> {
      return this.apiClient.post(this.userApiUrl + '/contractor', user)
          .pipe(map(res => {
              this.setUser(user);
              const userData = <User>(res);
              this.user.id = userData.id;
              return this.user;
          }));
  }

  updateUser(user: User): Observable<any> {
      return this.apiClient.patch(this.userApiUrl, user)
          .pipe(map(res => {
              this.user = <User>(res);
              return this.user;
          }));
  }

  updateProfilePicture(image: any): Observable<any> {
      return this.apiClient.upload(this.userApiUrl + '/profile-picture', image);
  }

  authenticate(email: string, password: string): Observable<string> {
      return this.apiClient.post(this.authApiUrl + '/worker', {email, password})
          .pipe(map(res => {
              const tokenObject = <any>(res);
              const token = tokenObject.token;
              this.apiClient.setToken(token);
              this.events.publish('user:login');
              return token;
          }));
  }

  tempTokenAuth(token: string): Observable<string> {
    return this.apiClient.post(this.authApiUrl + '/temp', {token})
      .pipe(map(res => {
        const tokenObject = <any>(res);
        const token = tokenObject.token;
        this.apiClient.setToken(token);
        this.events.publish('user:login');
        return token;
      }));
  }

  confirmCode(email: string, confirmation_code: string): Observable<string> {
      return this.apiClient.post(this.userApiUrl + '/confirm-code', {email, confirmation_code});
  }

  resendCode(email: string, phone_number?: string): Observable<string> {
    email = email.toLowerCase();
    return this.apiClient.post(this.userApiUrl + '/resend-code', {email, phone_number});
  }

  forgotPassword(email: string): Observable<string> {
      return this.apiClient.post(this.userApiUrl + '/forgot-password', {email});
  }

  resetPassword(email: string, confirmation_code: string, new_password: string, confirm_password: string): Observable<string> {
      return this.apiClient.post(this.userApiUrl + '/reset-password', {email, confirmation_code, new_password, confirm_password});
  }

  registerDevice(token: string): Observable<string> {
    return this.apiClient.post(this.userApiUrl + '/notification-token', {token})
        .pipe(map(res => {
            localStorage.setItem('push_token', token);
            return res;
        }));
  }

  removeDevice(): Observable<string> {
      const pushToken = localStorage.getItem('push_token');
      return this.apiClient.delete(this.userApiUrl + '/notification-token/' + pushToken)
          .pipe(map(res => {
              localStorage.removeItem('push_token');
              return res;
          }));
  }

  downloadAppText(): Observable<any> {
      return this.apiClient.post(this.userApiUrl + '/download?type=contractor', null);
  }

  // logout(): Observable<boolean> {
  //   return this.apiClient.get(this.authApiUrl + '/logout')
  //     .pipe(map(() => {
  //       this.storage.remove('localUser');
  //       this.apiClient.clearToken();
  //
  //       this.isAuthenticated = false;
  //       this.isMenuDisabled = true;
  //
  //       this.navCtrl.navigateRoot('/auth');
  //       return true;
  //     })
  //   );
  // }

  logout() {
    this.storage.remove('localUser');
    this.apiClient.clearToken();

    this.isAuthenticated = false;
    this.isMenuDisabled = true;

    this.navCtrl.navigateRoot('/auth');
  }

  callPhoneRequest(): Observable<any> {
    return this.apiClient.post(this.userApiUrl + '/phone-call', null);
  }
}
