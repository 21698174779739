import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortQuizPipe } from './sort-quiz.pipe';
import { SafeSVGlPipe } from './safeHtml.pipe';
import { CertificationsFilterPipe, WorkerCertificationsFilterPipe } from './filter-certs.pipe';

@NgModule({
  declarations: [SortQuizPipe, SafeSVGlPipe, CertificationsFilterPipe, WorkerCertificationsFilterPipe],
  imports: [CommonModule],
  exports: [SortQuizPipe, SafeSVGlPipe, CertificationsFilterPipe, WorkerCertificationsFilterPipe],
})
export class PipesModule {}
