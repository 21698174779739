import { Injectable, NgZone } from '@angular/core';
import { Platform, NavController, Events, LoadingController } from '@ionic/angular';
import { Location } from '@angular/common';
import { Storage } from '@ionic/storage';
import { UsersService } from './users-service';

@Injectable({
  providedIn: 'root',
})
export class DeeplinksService {

  redirectTarget: string;
  isActive = false;
  linkParams: any;

  constructor(
    private platform: Platform,
    private loading: LoadingController,
    private nav: NavController,
    private events: Events,
    private ngZone: NgZone,
    private storage: Storage,
    private userServ: UsersService
  ) {}

  redirect(target: any = this.redirectTarget, params: any = this.linkParams) {
    // check for desktop app and sets the specific action for it
    this.storage.get('tempLocation').then((pathName) => {

      if (pathName) {
        this.storage.get('tempToken').then(async tkn => {
          if (tkn) {

            const loading = await this.loading.create();
            loading.present();

            this.userServ.tempTokenAuth(tkn).subscribe(() => {
              loading.dismiss();
              this.nav.navigateForward([pathName]).then(() => {
              }).catch(() => {});
              this.userServ.isAuthenticated = true;
              this.userServ.isMenuDisabled = false;
              this.storage.remove('tempLocation');
              this.storage.remove('tempToken');
            }, () => {
              loading.dismiss();
              this.storage.remove('tempLocation');
              this.storage.remove('tempToken');
            });

          } else {
            this.nav.navigateForward([pathName]).then(() => {
              this.storage.remove('tempLocation');
              this.storage.remove('tempToken');
            }).catch(() => {});
          }
        });

      } else {
        this.nav.navigateForward(['/quizzes']).then(() => {
          this.storage.remove('tempLocation');
          this.storage.remove('tempToken');
        }).catch(() => {});
      }
    });
  }

}
