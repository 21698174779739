import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'certificationsFilter'
})
export class CertificationsFilterPipe implements PipeTransform {
  transform(Items: any[], input: string) {
    if (input) {
      input = input.toLowerCase();
      return Items.filter(function (el: any) {
        return el.description.toLowerCase().indexOf(input) > -1;
      });
    }
    return Items;
  }
}

@Pipe({
  name: 'workerCertificationsFilter'
})
export class WorkerCertificationsFilterPipe implements PipeTransform {
  transform(Items: any[], input: string) {
    if (input) {
      input = input.toLowerCase();
      return Items.filter(function (el: any) {
        return el.certification.description.toLowerCase().indexOf(input) > -1;
      });
    }
    return Items;
  }
}