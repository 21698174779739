import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'gate',
    pathMatch: 'full'
  },
  { path: 'gate', loadChildren: './auth/auth.module#AuthPageModule' },
  { path: 'auth', loadChildren: './auth/auth.module#AuthPageModule' },
  { path: 'quizzes', loadChildren: './views/quizzes/quizzes.module#QuizzesPageModule' },
  { path: 'certifications', loadChildren: './views/certifications/certifications.module#CertificationsPageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
