import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';
declare var cordova: any;

@Injectable({
  providedIn: 'root',
})
export class ApiClient {
    token: string;
    tokenPrm: Promise<any>;
    BASE_URL = environment.apiUrl;

    platformType: any = 'unknown';
    packageName: any = 'com.faberconnect.contractor.app';
    versionNumber: any = 'unknown';

    appVersionHeader = '';

    constructor(
      private http: HttpClient,
      private storage: Storage,
      public platform: Platform,
    ) {
        const prevToken = localStorage.getItem('token');
        console.log('This is a sqlite storage with initial token:', prevToken);
        this.tokenPrm = this.storage.get('token').then(token => {
            console.log('This is token from ionic storage: ', token);
            if (prevToken && prevToken.length > 0) {
                this.token = prevToken;
                localStorage.removeItem('token');
                this.storage.set('token', prevToken).catch(err => {});
            } else {
                this.token = token;
            }

            if (!this.token) {
              // if token null try cookies
              this.token = this.checkCookie('token');
            }
            console.log('This is the token being set: ', this.token);
        }).catch(error => {
            this.token = this.checkCookie('token');
        });
    }

    checkCookie(cname: string) {
      // get the cookie info and delete cookie to avoid future conflicts
      const name = cname + '=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      let tokenCookie;
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          tokenCookie = c.substring(name.length, c.length);
        }
      }
      if (tokenCookie) {
        // delete cookie
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      }
      return tokenCookie;
    }

    setToken(token: string) {
        this.token = token;
        this.storage.set('token', token);
    }

    clearToken() {
        this.token = null;
        this.storage.remove('token');
        localStorage.removeItem('token');
    }

    createAuthorizationHeader(headers: HttpHeaders) {
        return headers.append('Authorization', 'Bearer ' + this.token);
    }

    createContentTypeHeader(headers: HttpHeaders) {
        return headers.append('content-type', 'application/json');
    }

    createAppVersionHeader(headers: HttpHeaders) {
        // Generate app version info
        if (!this.appVersionHeader) {
            if ('undefined' !== typeof cordova) {
                if (this.platform.is('android')) {
                    this.platformType = 'android';
                } else if (this.platform.is('ios')) {
                    this.platformType = 'ios';
                }
                if (window['BuildInfo'] && window['BuildInfo'].packageName) {
                    this.packageName = window['BuildInfo'].packageName;
                }
                if (window['BuildInfo'] && window['BuildInfo'].version) {
                    this.versionNumber = window['BuildInfo'].version;
                }
                this.appVersionHeader = this.platformType + ' ' + this.packageName + ' ' + this.versionNumber;
            } else if (this.platform.is('desktop')) {
                this.platformType = 'web-app';
                this.appVersionHeader = this.platformType + ' ' + this.packageName;
            }
        }
        return headers.append('Faber-App-Version', this.appVersionHeader);
    }

    get(url): Observable<any> {
        return from(this.tokenPrm)
            .pipe(mergeMap(v => {
                let headers = new HttpHeaders();
                headers = this.createAuthorizationHeader(headers);
                headers = this.createAppVersionHeader(headers);
                return this.http.get(this.BASE_URL + url, {
                    headers: headers
                });
            }));
    }

    post(url, data, options: any = {}): Observable<any> {
      return from(this.tokenPrm)
        .pipe(mergeMap(v => {
          options.headers = new HttpHeaders();
          options.headers = this.createAuthorizationHeader(options.headers);
          options.headers = this.createAppVersionHeader(options.headers);
          return this.http.post<any>(this.BASE_URL + url, data, options);
        }));
    }

    patch(url, data): Observable<any> {
        return from(this.tokenPrm)
            .pipe(mergeMap(v => {
                let headers = new HttpHeaders();
                headers = this.createAuthorizationHeader(headers);
                headers = this.createAppVersionHeader(headers);
                headers = this.createContentTypeHeader(headers);
                return this.http.patch(this.BASE_URL + url, data, {
                    headers: headers
                });
            }));
    }

    delete(url): Observable<any> {
        return from(this.tokenPrm).pipe(mergeMap(v => {
          let headers = new HttpHeaders();
          headers = this.createAuthorizationHeader(headers);
          headers = this.createAppVersionHeader(headers);
          headers = this.createContentTypeHeader(headers);
          return this.http.delete(this.BASE_URL + url, {
            headers: headers
          });
        }));
    }

    upload(url, data): Observable<any> {
        let headers = new HttpHeaders();
        headers = this.createAuthorizationHeader(headers);
        headers = this.createAppVersionHeader(headers);
        const request = new HttpRequest(
            'POST', this.BASE_URL + url, data,
            {
                headers: headers,
                reportProgress: true
            }
        );

        return this.http.request(request);
    }
}
