import { Component } from '@angular/core';
import { Storage } from '@ionic/storage';
import { LoadingController, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UsersService } from './services/users-service';
import { ApiClient } from './services/api-client';
import { DeeplinksService } from './services/deeplinks-service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Quizzes',
      url: '/quizzes',
      icon: 'list'
    },
    {
      title: 'Certifications',
      url: '/certifications',
      icon: 'star'
    }
  ];

  loading: any;

  constructor(
    public loadingCtrl: LoadingController,
    public navCtrl: NavController,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public userServ: UsersService,
    private storage: Storage,
    private apiClient: ApiClient,
    private deeplinksService: DeeplinksService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.checkLoginToken();
    });
  }

  async checkLoginToken() {
    // saves the pathname to redirect the user aftter the login
    if (location.pathname.split('/')[1] !== 'auth') {
      this.storage.set('tempLocation', location.pathname);

      if (location.search) {
        const tempToken = location.search.split('t=')[1];
        if (tempToken) {
          this.storage.set('tempToken', tempToken);

        }
      }
    }

    this.loading = await this.loadingCtrl.create();
    this.loading.present();
    this.storage.ready().then(
      k => {
        // this.apiClient.tokenPrm;
        this.userServ.getUser().subscribe(
          async user => {
            this.loading.dismiss();

            // this.navCtrl.navigateRoot('/quizzes');
            // this.deeplinksService.redirect();

            this.userServ.isAuthenticated = true;
            this.userServ.isMenuDisabled = false;
            this.deeplinksService.redirect();
          },
          async error => {
            this.loading.dismiss();
            this.apiClient.clearToken();
            this.deeplinksService.redirect();
          }
        );
      }, async () => {
        this.loading.dismiss();
        this.apiClient.clearToken();
      });
  }
}
