import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertController, NavController } from '@ionic/angular';
import { UsersService } from './users-service';

@Injectable({
  providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {

    constructor(
      private alertCtrl: AlertController,
      private navCtrl: NavController,
      private userServ: UsersService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                let message = '';
                let error;
                try {
                  error = JSON.parse(err.error);
                } catch (e) {
                  error = err.error;
                }
                if (err.status === 400 && err.error && err.error.error && err.error.error === 'token_not_provided') {
                    // sent logout event to redirect to the login route
                    this.userServ.logout();
                } else if (err.status === 400 && err.error && err.error.error && err.error.error === 'token_invalid') {
                    // sent logout event to redirect to the login route
                    this.userServ.logout();
                } else if (err.status === 401) {
                    message = error;
                    this.doAlert('Unable to login', message);
                    // if not login route
                    if (!err.url.endsWith('auth/worker')) {
                        // sent logout event to redirect to the login route
                        this.userServ.logout();
                    }
                } else if (err.status === 400) {
                  // Show error alert
                  message = this.errorMsgHandling(err.error);
                  this.doAlert('Unable to process request', message);
                }  else if (err.status === 403) {
                  this.navCtrl.navigateForward(['/app/projects']);
                  // Show error alert
                  message = error;
                  this.doAlert('Unable to process request', message);
                } else if (err.status === 404) {
                  message = error;
                  if (message === 'Token Not Found') {
                    // this.eventsService.sendLogoutEvent();
                    this.navCtrl.navigateRoot(['/app/auth']);
                  } else {
                    this.navCtrl.navigateForward(['/app/errors/404'], { queryParams: { message }});
                  }
                } else if (err.status === 500) {
                  message = error;
                  this.navCtrl.navigateForward(['/app/errors/500'], { queryParams: { message }});
                }
            }
        }));

    }

    async doAlert(title: string, message: string) {
        const alert = await this.alertCtrl.create({
            header: title,
            message,
            buttons: ['OK']
        });
        await alert.present();
    }

    errorMsgHandling(error: any): string {
      let htmlResponse = '';
      Object.keys(error).map((labels) => {
        htmlResponse += `<p><strong>- ${error[labels]}</strong></p>`;
      });
      return htmlResponse;
    }
}
