import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortQuiz'
})
export class SortQuizPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.sort(this.fcompare);
  }

  fcompare(a, b) {
    if (a.status < b.status) {
      return -1;
    }
    if (a.status > b.status) {
      return 1;
    }
    return 0;
  }

}
